<template>
  <b-card
    v-if="Object.keys(user).length"
    class="blog-edit-wrapper"
  >

    <!-- form -->
    <b-form class="mt-2" @submit.prevent="save">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Poprzednie hasło"
            label-for="blog-edit-title"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-title"
              type="password"
              v-model="user.password"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Nowe hasło"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              type="password"
              v-model="user.newpassword"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Powtórz hasło"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              type="password"
              v-model="user.repeatpassword"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mr-1"
          >
            Zapisz zmiany
          </b-button>
          <b-button
            :to="{ name: 'apps-servicers' }"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            Anuluj
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: {
        password: '',
        newpassword: '',
        repeatpassword: ''
      },
      snowOption: {
        theme: 'snow',
      },
    }
  },
  created() {
  },
  methods: {
    save() {
      if(this.user.newpassword === this.user.repeatpassword) {
	      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } };
	      axios.post(`${process.env.VUE_APP_API_URL}ChangePassword`, this.user, config).then(response => {
	          this.$toast({
		        component: ToastificationContent,
		        props: {
		          title: 'Hasło zostało zmienione',
		          icon: 'BellIcon',
		          variant: 'success'
		        },
		      })
	      }).catch(error => {
	          this.$toast({
		        component: ToastificationContent,
		        props: {
		          title: error.response.data.message,
		          icon: 'BellIcon',
		          variant: 'danger'
		        },
		      })
	      })
      }
      else {
          this.$toast({
	        component: ToastificationContent,
	        props: {
	          title: 'Hasła nie są identyczne',
	          icon: 'BellIcon',
	          variant: 'danger'
	        },
	      })
      }
    },
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
